import tw from "tailwind-styled-components"

export const MobileDropdownMenuContainer = tw.div`flex flex-col bg-white fixed top-0 left-0 pt-12 w-[100vw] h-full`
export const MobileMenuContainer = tw.div`bg-white fixed top-0 left-0 pt-12 w-[100vw]`
export const MobileProfileMenuContainer = tw.div`bg-white fixed top-0 left-0 pt-12 w-[100vw] z-50 h-full`

export const DropdownOuterContainer = tw.div`w-[100%] px-4 mx-auto`
export const DropdownHeadlineContainer = tw.div`flex items-center justify-between`
export const MenuItemsContainer = tw.div`flex flex-col gap-5 mt-7 mb-9`
export const ContactInfo = tw.div`flex items-center justify-start gap-4`
