// ** Styled Components
import Link from "next/link"
import {
  ContactInfo,
  DropdownHeadlineContainer,
  DropdownOuterContainer,
  MobileMenuContainer,
  MobileProfileMenuContainer
} from "../MobileDropdownMenu/styles"

// import { setLiveCallerState } from 'src/store/apps/liveCaller'

// ** Style Guide
import { cx } from "class-variance-authority"
import Image from "next/image"
import Typography from "src/views/components/typography"
import { LOGOUT_URL, STATIC_URL } from "src/env"
import useUser from "src/hooks/useUser"
import usePersonApi from "src/server/queryHooks/usePersonApi"
import { useTranslation } from "next-i18next"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { formatNumberToGel } from "src/utils/helpers/currency/GelString"
import useBalanceCards from "src/server/queryHooks/useBalanceCards"

const menuOptions = [
  { label: "MY_PROFILE", href: "/profile" },
  { label: "CARDS_BALANCE", href: "/payment" },
  { label: "NOTIFICATIONS", href: "/notifications" },
  { label: "UPDATE_INFO", href: "/global/profile/" }
]

const MobileProfileMenu = ({ setShowProfileMenu }: any) => {
  const { t } = useTranslation("common")
  const { connectedUser } = useUser()
  const { naturalPersonData } = usePersonApi()
  const { balanceQuery } = useBalanceCards()

  return (
    <MobileProfileMenuContainer>
      <DropdownOuterContainer>
        <DropdownOuterContainer>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <img
                // rel="preload"
                src={
                  connectedUser?.user_id && naturalPersonData?.has_photo
                    ? `${STATIC_URL}/myjobs/profile/${connectedUser?.user_id}.jpg?v=${naturalPersonData?.photo_version}`
                    : "/images/default_image.svg"
                }
                alt="profile"
                className="h-8 w-8 rounded-full"
              />
              <Typography type="h5" weight="medium" className="text-[#272A37">
                {connectedUser?.user_name}
              </Typography>
            </div>
            <div className="cursor-pointer rounded-full bg-[#F2F2F6] p-3">
              <Image src="/icons/mobile_dropdown-close.svg" alt="close" onClick={() => setShowProfileMenu(false)} width={16} height={16} />
            </div>
          </div>
          <div className="flex flex-col gap-1 pb-4 pt-1">
            <Typography type="text" className="text-[18px] text-[#272A37]">
              {connectedUser?.username}
            </Typography>
            <Typography type="small" className="text-[#272A37]">
              ID:{connectedUser?.user_id}
            </Typography>
          </div>
          <hr></hr>
          <div>
            <div className="flex flex-col pb-4 pt-4">
              {menuOptions.map(({ label, href }) => (
                <Link
                  key={label}
                  href={href ?? "#"}
                  className={`${cx("pr-6 hover:bg-gray-100", label === "CARDS_BALANCE" && "flex items-center md:justify-between gap-4")}`}>
                  <Typography type="text" className="py-0.5 leading-[32px] text-[#1B1C1E]">
                    {t(label)}
                  </Typography>
                  {label === "CARDS_BALANCE" && (
                    <div className="flex h-7 w-14 justify-center rounded-3xl bg-[#4A6CFA1A]">
                      <span className="content-center font-tbcx-bold text-sm text-[#4A6CFA]">
                        {formatNumberToGel(balanceQuery.data?.data)}
                      </span>
                    </div>
                  )}
                </Link>
              ))}
            </div>
          </div>
          <hr></hr>
          <div className="pt-4">
            <Link
              key={t("LOG_OUT")}
              onClick={() => {
                localStorage.removeItem(QUERY_KEYS.USER_CV_TMP_FORM)
              }}
              className={`hover:bg-gray-100 `}
              href={`${LOGOUT_URL}https://myjobs.ge/`}>
              <Typography type="text" className="leading-[32px] text-[#1B1C1E]">
                {t("LOG_OUT")}
              </Typography>
            </Link>
          </div>
        </DropdownOuterContainer>
      </DropdownOuterContainer>
    </MobileProfileMenuContainer>
  )
}

export default MobileProfileMenu
