export const formatNumberToGel = (price?: number | string) => {
  let priceClone = price
  if (priceClone === null || priceClone === undefined) {
    priceClone = 0
  }

  const gelString = new Intl.NumberFormat("ka-GE", {
    style: "currency",
    currency: "GEL",
    currencyDisplay: "symbol"
  }).format(Number(priceClone))

  if (gelString.includes("GEL")) {
    return gelString.slice(4).concat(" ₾")
  }

  return gelString
}
