import { MobileHeaderBox } from "./Header.styles"

const MobileFooterBox = (props: any) => {
  const { title, src, alt } = props
  return (
    <MobileHeaderBox>
      <img src={src} className="h-5 w-5 rounded-full" width={20} height={19} alt={alt || "profile image"} />
      <span className="mobile-menu-title">{title}</span>
    </MobileHeaderBox>
  )
}

export default MobileFooterBox
