import {
  IPostBalanceFillCardPayload,
  IPostBalanceFillSavedCardPayload,
  IPostCardsAddFavoritePayload,
  IPostCardsAddResponse,
  IPostCardsGetResponse,
  IPostCardsRemoveFavoritePayload,
  IPostCardsRemovePayload
} from "src/types/balanceCardTypes"
import HttpService from "./HttpService"
import { IPostCardsAddPayload, IPostBalanceFillCardResponse } from "../../types/balanceCardTypes"
import { AxiosResponse } from "axios"

class BalanceCardsService extends HttpService {
  getBalance() {
    return this.get("/natural-person/balance")
  }

  postBalanceFillCard({ amount }: IPostBalanceFillCardPayload): Promise<AxiosResponse<IPostBalanceFillCardResponse>> {
    return this.post("/natural-person/balance/fill/card", { amount })
  }

  postBalanceFillSavedCard({ card_id, amount }: IPostBalanceFillSavedCardPayload) {
    return this.post("/natural-person/balance/fill/saved-card", { card_id, amount })
  }

  postCardsAdd({ return_key }: IPostCardsAddPayload): Promise<AxiosResponse<IPostCardsAddResponse>> {
    return this.post("/natural-person/cards/add", { return_key })
  }

  postCardsAddFavorite({ uuid }: IPostCardsAddFavoritePayload) {
    return this.post("/natural-person/cards/add-favourite", { uuid })
  }
  postCardsRemove({ uuid }: IPostCardsRemovePayload) {
    return this.post("/natural-person/cards/remove", { uuid })
  }
  postCardsRemoveFavorite({ uuid }: IPostCardsRemoveFavoritePayload) {
    return this.post("/natural-person/cards/remove-favourite", { uuid })
  }
  postCardsget(): Promise<AxiosResponse<IPostCardsGetResponse>> {
    return this.post("/natural-person/cards/get")
  }
}

export default new BalanceCardsService()
