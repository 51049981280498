import { useMutation, useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "./queryKeys"
import {
  IBalanceResponse,
  IPostBalanceFillCardPayload,
  IPostBalanceFillSavedCardPayload,
  IPostCardsAddFavoritePayload,
  IPostCardsAddPayload,
  IPostCardsGetResponse,
  IPostCardsRemoveFavoritePayload,
  IPostCardsRemovePayload
} from "../../types/balanceCardTypes"
import BalanceCardsService from "../services/BalanceCardsService"

const useBalanceCards = () => {
  const balanceQuery = useQuery<IBalanceResponse>({
    queryKey: [QUERY_KEYS.GET_BALANCE],
    queryFn: () => getBalance()
  })

  // Mutation to fill balance using a new card
  const fillBalanceCardMutation = useMutation((payload: IPostBalanceFillCardPayload) => BalanceCardsService.postBalanceFillCard(payload), {
    onSuccess: () => {
      balanceQuery.refetch() // Refetch balance on success
    }
  })

  // Mutation to fill balance using a saved card
  const fillBalanceSavedCardMutation = useMutation(
    (payload: IPostBalanceFillSavedCardPayload) => BalanceCardsService.postBalanceFillSavedCard(payload),
    {
      onSuccess: () => {
        balanceQuery.refetch()
      }
    }
  )

  // Mutation to add a card
  const addCardMutation = useMutation((payload: IPostCardsAddPayload) => BalanceCardsService.postCardsAdd(payload))

  // Mutation to add a card to favorites
  const addFavoriteMutation = useMutation((payload: IPostCardsAddFavoritePayload) => BalanceCardsService.postCardsAddFavorite(payload))

  // Mutation to remove a card
  const removeCardMutation = useMutation((payload: IPostCardsRemovePayload) => BalanceCardsService.postCardsRemove(payload))

  // Mutation to remove a card from favorites
  const removeFavoriteMutation = useMutation((payload: IPostCardsRemoveFavoritePayload) =>
    BalanceCardsService.postCardsRemoveFavorite(payload)
  )

  // Mutation to get cards
  const getCardsQuery = useQuery({
    queryFn: () => BalanceCardsService.postCardsget()
  })

  return {
    balanceQuery,
    fillBalanceCardMutation,
    fillBalanceSavedCardMutation,
    addCardMutation,
    addFavoriteMutation,
    removeCardMutation,
    removeFavoriteMutation,
    getCardsQuery
  }
}

export const getBalance = async () => {
  try {
    const response: any = await BalanceCardsService.getBalance()
    return response.data
  } catch (e: any) {
    console.error("getBalance")
    throw e // Rethrow the error
  }
}

export default useBalanceCards
