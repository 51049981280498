// ** Styled Components
import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "next-i18next"
import { STATIC_URL } from "src/env"
import useUser from "src/hooks/useUser"
import MobileFooterBox from "src/views/components/common/Header/MobileFooterBox"
import DefaultFooter from "src/views/components/footerDesktop"
import { MobileFooter } from "src/views/components/footerDesktop/style"
import MobileProfileMenu from "src/views/pages/main/MobileProfileMenu"
import { cx } from "class-variance-authority"
import { useNaturalPersonQuery } from "../../../query-library/queries/naturalPersonQueries"

const DefaulFooter = () => {
  const { t } = useTranslation("common")
  const { connectedUser } = useUser()
  const naturalPersonQuery = useNaturalPersonQuery()

  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const handleProfileClick = () => {
    if (connectedUser) {
      setShowProfileMenu(!showProfileMenu)
    }
  }

  const router = useRouter()

  return (
    <>
      <DefaultFooter />
      {showProfileMenu && <MobileProfileMenu setShowProfileMenu={setShowProfileMenu} />}

      {/* {showProfileMenu && <MobileProfileMenu />} */}
      <MobileFooter className="mobile-menu">
        <Link href="/" className={cx("relative", router.pathname === "/" ? "active-icon" : "")}>
          <div className="h-6 flex items-nceter justify-center">
            <svg className="stroke" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.5 20V9.48908L12.5 3.26686L20.5 9.48908V20C20.5 20.2652 20.3946 20.5196 20.2071 20.7071C20.0196 20.8946 19.7652 21 19.5 21H5.5C5.23478 21 4.98043 20.8946 4.79289 20.7071C4.60536 20.5196 4.5 20.2652 4.5 20Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className={cx("mobile-menu-title", router.pathname === "/" && "font-tbcx-medium")}>{t("MAIN")}</span>
          {router.pathname == "/" && <div className="dot-icon rounded-full bg-purple-500"></div>}
        </Link>

        <Link href="/vacancy" className={cx("relative", router.pathname === "/vacancy" ? "active-icon" : "")}>
          <div className="h-6 flex items-nceter justify-center">
            <svg className="fill" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.1665 4.5V2.5H8.1665V4.5H12.1665ZM2.1665 7.5V16.5C2.1665 17.05 2.6165 17.5 3.1665 17.5H17.1665C17.7165 17.5 18.1665 17.05 18.1665 16.5V7.5C18.1665 6.95 17.7165 6.5 17.1665 6.5H3.1665C2.6165 6.5 2.1665 6.95 2.1665 7.5ZM18.1665 4.5C19.2765 4.5 20.1665 5.39 20.1665 6.5V17.5C20.1665 18.61 19.2765 19.5 18.1665 19.5H2.1665C1.0565 19.5 0.166504 18.61 0.166504 17.5L0.176504 6.5C0.176504 5.39 1.0565 4.5 2.1665 4.5H6.1665V2.5C6.1665 1.39 7.0565 0.5 8.1665 0.5H12.1665C13.2765 0.5 14.1665 1.39 14.1665 2.5V4.5H18.1665Z"
                fill="#222222"
              />
            </svg>
          </div>
          <span className={cx("mobile-menu-title", router.pathname === "/vacancy" && "font-tbcx-medium")}>{t("VACANCIES")}</span>
          {router.pathname == "/vacancy" && <div className="dot-icon rounded-full bg-purple-500"></div>}
        </Link>

        <Link href="/cv" className={cx("relative", router.pathname === "/cv" ? "active-icon" : "")}>
          <div className="h-6 flex items-nceter justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Property 1=blank">
                <path
                  id="Vector"
                  d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>

          <span className={cx("mobile-menu-title", router.pathname === "/cv" && "font-tbcx-bold")}>{t("RESUME")}</span>
          {router.pathname == "/cv" && <div className="dot-icon rounded-full bg-purple-500"></div>}
        </Link>

        <Link
          href={connectedUser ? "#" : "/profile"}
          className={router.pathname === "/profile" ? "active-icon" : ""}
          onClick={handleProfileClick}>
          <MobileFooterBox
            title={connectedUser ? t("PROFILE") : t("ENTER")}
            src={
              connectedUser?.user_id && naturalPersonQuery.data?.has_photo
                ? `${STATIC_URL}/myjobs/profile/${connectedUser?.user_id}.jpg`
                : "/images/default_image.svg"
            }
            alt="profile"
          />
          {router.pathname == "/profile" && <div className="dot-icon rounded-full bg-purple-500"></div>}
        </Link>
      </MobileFooter>
    </>
  )
}
export default DefaulFooter
